/**
 * @ComponentFor RelatedQuestionsBlock
 */

import React from 'react';
import { styled } from '@glitz/react';
import { epiPropertyValue, EpiProperty } from '@avensia/scope-episerver';
import RelatedQuestionsBlockType from './RelatedQuestionsBlock.type';
import { pixelsToUnit, iota } from 'Shared/Style';

type PropType = RelatedQuestionsBlockType;

const RelatedQuestionsBlock = (props: PropType) => {
  return (
    <div>
      {!!epiPropertyValue(props.title) && <Title>{epiPropertyValue(props.title)}</Title>}
      <EpiProperty for={props.questions} />
    </div>
  );
};

const Title = styled.span({
  display: 'block',
  fontSize: iota,
  marginBottom: pixelsToUnit(20),
  fontWeight: 'bold',
  letterSpacing: pixelsToUnit(2.4),
});

export default RelatedQuestionsBlock;
